import { datadogRum } from '@datadog/browser-rum';
import { NEXT_PUBLIC_ENV, SERVICE_NAME, datadogConfig } from '../config';
import { getCookies } from 'cookies-next';

const rum = () => {
    if (typeof window !== 'undefined' && NEXT_PUBLIC_ENV !== 'local') {
        const { applicationId, clientToken } = datadogConfig;
        datadogRum.onReady(() => {
            datadogRum.init({
                applicationId,
                clientToken,
                site: 'datadoghq.com',
                service: `${SERVICE_NAME}`,
                sessionSampleRate: 100,
                version: '1.0.0',
                trackUserInteractions: true,
                defaultPrivacyLevel: 'mask-user-input',
                env: NEXT_PUBLIC_ENV,
                allowedTracingUrls: [
                    (url) => url.includes('mortgageapi') && !url.includes('whoAmI'),
                    { match: '*/homeloans/loan-officer/*', propagatorTypes: ['datadog'] },
                ],
                beforeSend: () => {
                    // eslint-disable-next-line zillow/@typescript-eslint/naming-convention
                    const { zjs_user_id, zguid } = getCookies();
                    // eslint-disable-next-line zillow/@typescript-eslint/strict-boolean-expressions
                    if (!!zjs_user_id && zjs_user_id !== 'null') {
                        datadogRum.setUser({
                            id: zjs_user_id,
                            zuid: zjs_user_id,
                        });
                    } else {
                        datadogRum.setUser({
                            id: zguid,
                            guid: zguid,
                        });
                    }
                    return true;
                },
            });

            datadogRum.startSessionReplayRecording();
        });
    } else {
        datadogRum.stopSessionReplayRecording();
    }
};

rum();

export const ddRum = {
    addAction: datadogRum.addAction,
};
