/* eslint-disable zillow/@typescript-eslint/restrict-template-expressions */
/* eslint-disable zillow/react/require-default-props */
/* eslint-disable zillow/@typescript-eslint/strict-boolean-expressions */
/* eslint-disable zillow/@typescript-eslint/naming-convention */
/* eslint-disable zillow/react/no-array-index-key */
/* eslint-disable no-confusing-arrow */
import type { LoanOfficerData, LoanOfficerReview } from '../models/loanOfficer.model';
import type { SERVICE_MAP } from '../utils/formatter';
import {
    getLenderDisplayName,
    formatLocation,
    getReviewerName,
    loanProgramName,
    loanPurposeName,
    loanTypeName,
    reviewDetail,
    reviewSatisfaction,
    serviceDate,
    serviceProvided,
    closeDateSatisfaction,
} from '../utils/formatter';
import {
    Anchor,
    Flex,
    Heading,
    MediaObject,
    Paragraph,
    RatingStars,
    ShowHideWordCount,
    Tag,
    Text,
    mediaMixin,
    spaceMixin,
    token,
} from '@zillow/constellation';
import React from 'react';

import type { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { LenderImageAvatar } from './LenderImageAvatar';

const getReviewerNameAndLocation = ({
    submitter,
    location,
}: {
    submitter: LoanOfficerReview['submitter'];
    location: LoanOfficerReview['location'];
}): string => `${getReviewerName(submitter)} from ${formatLocation(location)}`;

export const getDate = (dateStr?: string, options?: Record<string, string>): string => {
    if (dateStr) {
        const dateObj = new Date(dateStr);
        const year = dateObj.getUTCFullYear();
        const month = dateObj.getUTCMonth();
        const date = dateObj.getUTCDate();
        const dateUTC = new Date();
        dateUTC.setUTCFullYear(year);
        dateUTC.setUTCMonth(month);
        dateUTC.setUTCDate(date);
        const serviceDateObj = new Date(dateUTC);
        return serviceDateObj.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions);
    }
    return '';
};
const avoidReactConsoleError = 'width: 100%';
const ReviewDetailsWrapper = styled(Flex)`
    width: 90%;
    ${mediaMixin(
        'sm_lte',
        css`
            ${avoidReactConsoleError}
        `
    )};
`;

const StyledLenderResponse = styled(MediaObject)`
    width: 100%;
    padding: ${spaceMixin('xs')};
    background-color: ${token('colors.backgroundLight')};
`;

const Detail = ({
    title,
    children,
    dataTestId,
}: {
    title: string;
    children?: React.ReactNode | string | null;
    dataTestId?: string;
}): JSX.Element | null =>
    children ? (
        <Flex
            display="flex"
            style={{ width: '100%' }}
            flexFlow={{ default: 'row wrap', sm_lte: 'column wrap' }}
            marginBottom={{ default: 'sm', sm_lte: 'xs' }}
            marginRight="xs"
            data-testid={dataTestId}
        >
            <Text
                fontType="bodySmallHeading"
                fontColor="textLight"
                marginRight={{ default: 'md', sm_lte: 0 }}
                data-testid={`${dataTestId}:title`}
            >
                {title}
            </Text>
            <Text fontType="bodySmall" data-testid={`${dataTestId}:body`}>
                {children}
            </Text>
        </Flex>
    ) : null;

const ReviewDetails = ({ review }: { review: LoanOfficerReview }) => {
    const {
        serviceProvided: serviceProvidedName,
        dateOfService = '',
        loanPurpose,
        interestRateSatisfaction,
        closeDateSatisfaction: closeSatisfaction,
        closingCostsSatisfaction,
    } = review;
    const service = serviceProvidedName ?? '';
    return (
        <ReviewDetailsWrapper
            display="flex"
            justifyContent="space-between"
            data-testid="review-card:details-wrapper"
        >
            <div>
                <Detail title="Loan Status" dataTestId="review-card:loan-status">
                    {serviceProvided({
                        service: service as keyof typeof SERVICE_MAP,
                        dateOfService: dateOfService ?? '',
                    })}
                </Detail>
                <Detail title="Loan Type" dataTestId="review-card:loan-type">
                    {loanPurposeName(loanPurpose)}
                </Detail>
            </div>
            <div>
                <Detail title="Service Date" dataTestId="review-card:service-date">
                    {serviceDate({
                        service: service as keyof typeof SERVICE_MAP,
                        dateOfService: dateOfService ?? '',
                    })}
                </Detail>
                <Detail title="Interest Rate" dataTestId="review-card:interest-rate">
                    {reviewSatisfaction(interestRateSatisfaction)}
                </Detail>
            </div>
            <div>
                <Detail title="Close on time" dataTestId="review-card:close-time">
                    {closeDateSatisfaction(closeSatisfaction)}
                </Detail>
                <Detail title="Fees/Closing costs" dataTestId="review-card:close-cost">
                    {reviewSatisfaction(closingCostsSatisfaction)}
                </Detail>
            </div>
        </ReviewDetailsWrapper>
    );
};

const ReviewTags = ({ review }: { review: LoanOfficerReview }): React.ReactElement | null => {
    const { loanType, loanProgram, details } = review;
    const reviewDetails = details?.map((detail) => reviewDetail(detail)) ?? [];
    const combined = [
        loanProgramName(loanProgram),
        loanTypeName(loanType),
        // eslint-disable-next-line zillow/@typescript-eslint/no-unsafe-return
        ...reviewDetails,
    ].filter(Boolean);

    if (combined.length === 0) {
        return null;
    }

    return (
        <Flex
            display="flex"
            flexDirection="row"
            marginBottom="sm"
            flexWrap="wrap"
            gap="xs"
            data-testid="review-card:tag-container"
        >
            {combined.map((tag, i) => (
                // eslint-disable-next-line zillow/@typescript-eslint/restrict-template-expressions
                <Tag key={`${tag}-${i}`} appearance="gray" data-testid={`review-card:tag-${i}`}>
                    {tag}
                </Tag>
            ))}
        </Flex>
    );
};

const LenderResponseWrapper = ({
    children,
    lender,
}: {
    children?: React.ReactNode | string | null;
    lender: { name: string; imageUrl?: string };
}) => (
    <StyledLenderResponse
        align="center"
        direction="row"
        gutter="xs"
        media={<LenderImageAvatar lender={lender} />}
        data-testid="review-card:response-container"
    >
        <Flex marginLeft={0} flexGrow={1}>
            {children}
        </Flex>
    </StyledLenderResponse>
);

export interface LenderResponseProps {
    name: string;
    screenName: string;
    imageUrl?: string;
}

const Review: FunctionComponent<{
    review: LoanOfficerReview;
    dataTestId: string;
}> = ({ review, dataTestId }) => {
    const {
        rating,
        submitter,
        updated,
        location,
        content = '',
        response: { content: responseContent = '' } = {},
        response,
        reviewerName,
    } = review;
    const lenderName = getLenderDisplayName(response ?? ({} as LoanOfficerData), true);
    const lenderImageUrl = response != null && 'imageUrl' in response ? response.imageUrl : '';

    const [contentHidden, setContentHidden] = React.useState(true);

    return (
        <Flex
            marginTop="lg"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-evenly"
            style={{ width: '100%' }}
            data-testid={dataTestId}
        >
            <Flex
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: '100%' }}
            >
                {rating != null && (
                    <RatingStars
                        as="div"
                        value={rating}
                        aria-label={`${rating} out of 5 stars`}
                        style={{ margin: '0px 4px 0px 0px' }}
                        data-testid="review-card:stars"
                    />
                )}
                {updated && (
                    <Text fontType="bodySmall">
                        {getDate(updated, {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        })}
                    </Text>
                )}
            </Flex>
            <Heading level="5" data-testid="review-card:heading">
                {review.title}
            </Heading>
            <Text as="div" fontType="bodySmall" data-testid="review-card:reviewer-info">
                {getReviewerNameAndLocation({
                    submitter: submitter ?? reviewerName,
                    location,
                })}
            </Text>
            <Paragraph
                style={{ overflowWrap: 'anywhere' }}
                marginY="md"
                marginX={0}
                data-testid="review-card:review-content"
            >
                <ShowHideWordCount
                    text={content ?? ''}
                    isHidden={contentHidden}
                    onClick={() => setContentHidden(!contentHidden)}
                    renderButton={({ isHidden, ...props }: { isHidden: boolean }) =>
                        content != null && content.split(' ').length > 100 ? (
                            <Anchor
                                as="button"
                                aria-expanded={!isHidden}
                                {...props}
                                data-testid="review-card:read-more-content-button"
                            >
                                {isHidden ? 'Read More' : 'Read Less'}{' '}
                            </Anchor>
                        ) : null
                    }
                />
            </Paragraph>
            <ReviewDetails review={review} />
            <ReviewTags review={review} />
            {responseContent ? (
                <LenderResponseWrapper lender={{ name: lenderName, imageUrl: lenderImageUrl }}>
                    <Text
                        fontColor="blue500"
                        as="strong"
                        data-testid="review-card:response-lender-name"
                    >
                        {lenderName}{' '}
                    </Text>
                    <Text data-testid="review-card:response-content">{responseContent}</Text>
                </LenderResponseWrapper>
            ) : null}
        </Flex>
    );
};

export default Review;
