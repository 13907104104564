import { mediaBreakpointMixin } from '@zillow/constellation';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

const VideoContainer = styled.iframe`
    display: block;
    position: relative;
    width: 100%;
    height: 194px;
    margin-bottom: 16px;
    @media ${mediaBreakpointMixin('md')} {
        height: 386px;
    }
`;

interface ProfileVideoProps {
    videoUrl: string;
}

/**
 * Renders a profile video component.
 *
 * @param {ProfileVideoProps} videoUrl - The URL of the video to be rendered.
 * @return {ReactElement} The rendered profile video component.
 */
export const ProfileVideo: FC<ProfileVideoProps> = ({ videoUrl }) => (
    <VideoContainer src={videoUrl} />
);
