import type { ModalDialogProps } from '@zillow/constellation';
import {
    IconMail,
    IconPhone,
    IconUser,
    Button,
    ModalDialog,
    Trigger,
    Grid,
    Flex,
    Spacer,
    Form,
    Text,
} from '@zillow/constellation';
import type { FC } from 'react';
import React from 'react';
import { LenderImageAvatar } from './LenderImageAvatar';
import { useForm, RHFSchemaGeneratedField, FIELD_TYPES } from '@zillow/zgm-react-hook-form';
import type { ContactLender, ContactLenderSchema } from '../models/loanOfficer.model';
import { contactLenderSchemaShape } from '../models/loanOfficer.model';
import { useToast } from '@zillow/zhl-common-ui-utils';
import { v4 } from 'uuid';
import { ddRum } from '@/utils/rum';

interface ContactLenderModalProps {
    lender: {
        name: string;
        firstName: string;
        imageUrl: string;
        lenderId: string;
    };
}

export const ContactLenderModal: FC<ContactLenderModalProps> = ({
    lender: { lenderId },
    lender,
}) => {
    const toast = useToast();
    // eslint-disable-next-line zillow/@typescript-eslint/unbound-method
    const { fields, errors, handleSubmit, reset } = useForm<ContactLenderSchema>(
        {
            validationSchema: contactLenderSchemaShape,
        },
        {
            valuesToPersist: [],
        }
    );
    const submitForm = async (formData: ContactLenderSchema, closeModal: () => void) => {
        const formValues = { ...formData } as unknown as ContactLender;
        if (formValues.phoneNumber === '') {
            delete formValues.phoneNumber;
        }
        formValues.userSessionId = v4();
        formValues.lenderId = lenderId;
        formValues.partnerId = process.env.NEXT_PUBLIC_MORTGAGE_BACKEND_PARTNER_ID ?? '';
        ddRum.addAction('submit contact button clicked');
        const response = await fetch('/homeloans/loan-officer/api/contactLender', {
            method: 'POST',
            body: JSON.stringify(formValues),
        });
        const { contactId } = await response.json();
        if (response.status === 500) {
            toast(`Error: network error`, { appearance: 'error' });
            ddRum.addAction('submit contact button failure');
        }
        if (response.status === 400) {
            toast(`Error: ${response.statusText}`, { appearance: 'error' });
            ddRum.addAction('submit contact button failure');
        }
        if (typeof contactId === 'string') {
            toast(`Message sent!`, { appearance: 'success' });
            ddRum.addAction('submit contact button success');
            reset();
            closeModal();
        }
    };

    const contactLenderModal: FC<{ modalDialogProps: ModalDialogProps; closeModal: () => void }> = (
        modalDialogProps,
        closeModal
    ) => (
        <ModalDialog
            {...modalDialogProps}
            // @ts-expect-error ModalDialog.SIZES.MD is sent available but not in the types
            size={ModalDialog.SIZES.MD as string}
            shouldCloseOnOutsideClick={false}
            shouldCloseOnESCKeyPress={false}
            renderBody={
                <Grid
                    display="grid"
                    // eslint-disable-next-line zillow/@typescript-eslint/naming-convention
                    gridTemplateColumns={{ default: 'repeat(12, 1fr)', md_lte: 'repeat(6, 1fr)' }}
                    // eslint-disable-next-line zillow/@typescript-eslint/naming-convention
                    gap={{ default: 'sm', sm_lte: 'xs' }}
                >
                    <Grid gridRowStart="1" gridColumnStart="1" gridColumnEnd="span 6">
                        <Flex display="flex" justifyContent="center" flexGrow={1}>
                            <LenderImageAvatar lender={lender} />
                        </Flex>
                    </Grid>
                    <Grid
                        // eslint-disable-next-line zillow/@typescript-eslint/naming-convention
                        gridRowStart={{ default: '1', md_lte: '2' }}
                        // eslint-disable-next-line zillow/@typescript-eslint/naming-convention
                        gridColumnStart={{ default: '7', md_lte: '1' }}
                        gridColumnEnd="span 6"
                    >
                        <Spacer paddingX="sm" paddingBottom="sm">
                            <Text
                                as="h5"
                                fontType="h6"
                                color="gray500"
                                data-testid="contact-lender-modal:header"
                            >
                                {`Contact ${lender.name}`}
                            </Text>
                            <Spacer paddingTop="sm">
                                <Form
                                    onSubmit={handleSubmit(async (values) =>
                                        submitForm(values, closeModal)
                                    )}
                                    data-testid="contact-lender-modal:form"
                                >
                                    <RHFSchemaGeneratedField
                                        error={errors.firstName}
                                        field={fields.firstName}
                                        placeholder="John"
                                        data-testid="contact-lender-modal:first-name"
                                        leftAdornment={<IconUser />}
                                    />
                                    <RHFSchemaGeneratedField
                                        error={errors.lastName}
                                        field={fields.lastName}
                                        required
                                        placeholder="Smith"
                                        leftAdornment={<IconUser />}
                                        data-testid="contact-lender-modal:last-name"
                                    />
                                    <RHFSchemaGeneratedField
                                        error={errors.phoneNumber}
                                        field={fields.phoneNumber}
                                        placeholder="(123) 456-7890"
                                        leftAdornment={<IconPhone />}
                                        data-testid="contact-lender-modal:phone-number"
                                    />
                                    <RHFSchemaGeneratedField
                                        error={errors.emailAddress}
                                        field={fields.emailAddress}
                                        required
                                        placeholder="example@email.com"
                                        leftAdornment={<IconMail />}
                                        data-testid="contact-lender-modal:email-address"
                                    />
                                    <RHFSchemaGeneratedField
                                        error={errors.message}
                                        field={fields.message}
                                        placeholder="Write your message here"
                                        type={FIELD_TYPES.TEXTAREA}
                                        data-testid="contact-lender-modal:message"
                                    />
                                    <Spacer paddingY="sm">
                                        <Button
                                            buttonType="primary"
                                            data-testid="contact-lender-modal:submit-button"
                                            type="submit"
                                            fluid
                                        >
                                            Submit to {lender.firstName}
                                        </Button>
                                    </Spacer>
                                </Form>
                            </Spacer>
                        </Spacer>
                    </Grid>
                </Grid>
            }
        />
    );

    return (
        <Trigger triggered={contactLenderModal}>
            <Button
                fluid
                buttonType="secondary"
                style={{ margin: '20px 0px 0px', padding: '8px 4px' }}
                data-testid="loan-officer-information:message-button"
            >
                Send a message
            </Button>
        </Trigger>
    );
};
