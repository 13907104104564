import React from 'react';
import { Accordion, Card, Text, token } from '@zillow/constellation';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import { CreditScoreFAQ, PreQualifyMeaningFAQ, WhatHappensAfterwardsFAQ } from './StaticFAQcontent';

const FAQcard = styled(Card)`
    border: 0;
    width: 100%;
    box-shadow: none;
    padding: 20px 0px 0px 0px;
`;
const Question = styled(Text)`
    font-size: token('fontSizes.body');
    color: token('colors.gray600');
    font-weight: token('fontWeights.body');
`;
const FAQaccordion = styled(Accordion)`
    margin-top: ${token('spacing.md')}px;
`;
const LoanOfficerFAQ: FunctionComponent = () => (
    <FAQcard>
        <Text as="h5" fontType="h6" data-testid="loan-faqs:heading">
            Frequently asked questions
        </Text>
        <FAQaccordion>
            <Accordion.Item>
                <Accordion.Header>
                    <Question data-testid="loan-faqs:prequal-header">
                        What does it mean to get pre-qualified?
                    </Question>
                </Accordion.Header>
                <Accordion.Panel data-testid="loan-faqs:prequal-content">
                    <PreQualifyMeaningFAQ />
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item>
                <Accordion.Header>
                    <Question data-testid="loan-faqs:credit-score-header">
                        Will it affect my credit score?
                    </Question>
                </Accordion.Header>
                <Accordion.Panel data-testid="loan-faqs:credit-score-content">
                    <CreditScoreFAQ />
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item>
                <Accordion.Header>
                    <Question data-testid="loan-faqs:after-header">
                        What happens afterwards?
                    </Question>
                </Accordion.Header>
                <Accordion.Panel data-testid="loan-faqs:after-content">
                    <WhatHappensAfterwardsFAQ />
                </Accordion.Panel>
            </Accordion.Item>
        </FAQaccordion>
    </FAQcard>
);
export default LoanOfficerFAQ;
