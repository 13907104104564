import React from 'react';
import { Button, Divider, Flex, Heading, RatingStars, Text } from '@zillow/constellation';

import type { FunctionComponent } from 'react';

import Review from './ReviewComponent';
import type { ReviewContainerProps } from '../models/reviews.model';

const PAGE_SIZE = 5;

const ReviewContainer: FunctionComponent<ReviewContainerProps> = ({
    firstName,
    lender,
    rating,
    totalReviews,
    reviews,
    iconFn,
    isWritingReviewVisible,
}) => {
    const [page, setPage] = React.useState(1);

    return (
        <Flex
            marginTop="lg"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ width: '100%' }}
        >
            <Flex
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap="sm"
                alignItems="center"
                justifyContent="space-between"
                style={{ width: '100%' }}
            >
                <Flex
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="space-between"
                >
                    <Heading
                        level="4"
                        data-testid="loan-officer-reviews:heading"
                        marginRight="md"
                    >{`${firstName}'s Reviews`}</Heading>
                    <Flex display="flex" justifyContent="flex-start" alignItems="center">
                        <RatingStars
                            as="div"
                            value={rating as number}
                            aria-label={`${rating} out of 5 stars`}
                            style={{ margin: '0px 4px' }}
                            iconFn={iconFn}
                        />
                        <Text
                            as="div"
                            fontType="bodySmall"
                            style={{
                                display: 'block',
                                margin: '0px 4px',
                            }}
                            data-testid="loan-officer-reviews:rating"
                        >
                            {rating} stars •{' '}
                            <Text
                                style={{ color: '#2A2A33', fontSize: '14px' }}
                                data-testid="loan-officer-reviews:total-reviews"
                            >
                                {totalReviews} {totalReviews === 1 ? 'Review' : 'Reviews'}
                            </Text>
                        </Text>
                    </Flex>
                </Flex>
                {lender.screenName && isWritingReviewVisible ? (
                    <Button
                        marginY={0}
                        buttonType="secondary"
                        as="a"
                        href={`lender-review/${lender.screenName}`}
                        style={{ alignSelf: 'center' }}
                        data-testid="loan-officer-reviews:write-review-button"
                    >
                        Write a review
                    </Button>
                ) : null}
            </Flex>
            {typeof reviews !== 'undefined' && reviews.length > 0 ? (
                reviews.slice(0, page * PAGE_SIZE).map((review, idx) => (
                    <React.Fragment key={`${review.reviewId ?? ''}`}>
                        <Review
                            review={review}
                            dataTestId={`loan-officer-reviews:review-card-${idx}`}
                        />
                        {idx !== reviews.length - 1 && idx !== page * PAGE_SIZE - 1 && (
                            <Divider
                                marginTop="lg"
                                length="fluid"
                                data-testid={`loan-officer-reviews:divider-${idx}`}
                            />
                        )}
                    </React.Fragment>
                ))
            ) : (
                <Text fontType="bodyHeadingLarge">No reviews yet. Be the first to review!</Text>
            )}
            {reviews.length > page * PAGE_SIZE && (
                <Button
                    marginY="lg"
                    buttonType="secondary"
                    onClick={() => setPage(page + 1)}
                    style={{ alignSelf: 'center' }}
                    data-testid="loan-officer-reviews:show-more-button"
                >
                    Show More
                </Button>
            )}
        </Flex>
    );
};

export default ReviewContainer;
