/* eslint-disable zillow/@typescript-eslint/no-unsafe-member-access */
/* eslint-disable zillow/@typescript-eslint/naming-convention */
/* eslint-disable no-confusing-arrow */
/* eslint-disable zillow/@typescript-eslint/restrict-template-expressions */
import {
    Anchor,
    Avatar,
    Button,
    ButtonGroup,
    Divider,
    Flex,
    Heading,
    IconMailOutline,
    IconPhoneOutline,
    IconStar,
    IconStar25,
    IconStar50,
    IconStar75,
    IconStarOutline,
    MediaObject,
    Page,
    Paragraph,
    RatingStars,
    ShowHideWordCount,
    Text,
    TextButton,
    mediaBreakpointMixin,
    token,
} from '@zillow/constellation';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Image from 'next/image';

import type { GetStaticPaths, GetStaticProps, GetStaticPropsContext, NextPage } from 'next';
import styled from 'styled-components';
import LoanOfficerFAQ from '../components/LoanOfficerFAQ';
import AuthoredReviewContainer from '@/components/AuthoredReviewContainer';
import type {
    LoanOfficerData,
    LoanOfficerReview,
    ResponseInformation,
} from '../models/loanOfficer.model';
import { isLoanOfficerList } from '../models/loanOfficer.model';
import { IS_ISR_REQUEST } from '../config';

import logger from '../utils/logger';
import { getStateName } from '../utils/states';
import type { StateAbbreviation } from '../utils/states';
import Link from 'next/link';
import { ddRum } from '@/utils/rum';
import ReviewContainer from '../components/ReviewContainer';
import { ContactLenderModal } from '../components/ContactLenderModal';
import { PREMIER_AGENT_PATH } from '@/utils/constants';
import {
    getCatchClauseErrorMessage,
    numberFormatter,
    getLenderDisplayName,
} from '@/utils/formatter';
import { ProfileVideo } from '@/components/ProfileVideo';
import {
    getLoanOfficerList,
    getLoanOfficerNameFromNmlsid,
    getLenderInformation,
    getLoanOfficerImage,
    getLoanOfficerReviewInformation,
    hasValidReviews,
} from '@/utils/mortgageFetching';
import type { LoanOfficerReviewInput } from '../utils/interfaces';
import type { ErrorObject } from '@/utils/types';
import type { AuthenticatedUser } from '@/utils/auth';
import { getAuthInfo } from '@/utils/auth';

const REVALIDATE_TIME = 1800;

interface LoanOfficerPageProps {
    loanOfficer?: LoanOfficerData;
    loanOfficerName: string;
    loanOfficerImage: string | ErrorObject;
    reviews: LoanOfficerReview[] | ErrorObject;
    error?: string;
    isPremierAgent: boolean;
    videoUrl?: string;
    authoredReviews: LoanOfficerReview[] | ErrorObject;
}

interface LoanOfficerURLPath {
    params: { loanOfficerPath: string[] };
}

const ProfileCard = styled.div`
    background-color: ${token('colors.blue100')};
    border-radius: ${token('radii.md')}px;
    padding: 20px 18px;
    padding-top: ${token('spacing.lg')}px;
    padding-bottom: ${token('spacing.lg')}px;
    padding-left: ${token('spacing.lg')}px;
    padding-right: ${token('spacing.lg')}px;
    margin: ${token('spacing.none')}px;
    margin-bottom: ${token('spacing.none')}px;
    max-width: 100%;

    @media ${mediaBreakpointMixin('lg')} {
        padding: 20px 18px;
        padding-left: ${token('spacing.sm')}px;
        padding-right: ${token('spacing.sm')}px;
        margin-bottom: ${token('spacing.none')}px;
        max-width: 375px;
        flex: 0 0 375px;
        position: sticky;
        top: 20px;
    }
`;

const ContactCard = styled.div`
    border-color: ${token('colors.gray300')};
    border-radius: ${token('radii.lg')}px;
    border-style: solid;
    border-width: 1px;
    padding: 0px;
    margin: 20px 0px 20px;
    width: 100%;
`;

const FlexContainer = styled(Flex)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;

    @media ${mediaBreakpointMixin('lg')} {
        flex-direction: row;
        column-gap: 40px;
        margin-top: 24px;
    }
`;

export const roundUpOrDownToHalfStars = (val: number, i: number) => {
    let Icon = IconStarOutline;

    if (val >= 0.9) {
        Icon = IconStar;
    } else if (val >= 0.65) {
        Icon = IconStar75;
    } else if (val >= 0.4) {
        Icon = IconStar50;
    } else if (val >= 0.15) {
        Icon = IconStar25;
    }

    return <Icon aria-hidden="true" key={i} focusable="false" />;
};

const LoanOfficerPage: NextPage<LoanOfficerPageProps> = ({
    loanOfficer,
    loanOfficerName,
    loanOfficerImage,
    reviews,
    isPremierAgent,
    videoUrl,
    error,
}) => {
    const [aboutMeHidden, setaboutMeHidden] = useState(true);
    const [stateLicensesHidden, setStateLicensesHidden] = useState(true);
    const hasVideo = process.env.NEXT_PUBLIC_HAS_PROFILE_VIDEO === 'true';
    const [authoredReviews, setAuthoredReviews] = useState<LoanOfficerReview[]>([]);
    const [isWritingReviewVisible, setIsWritingReviewVisible] = useState(true);

    useEffect(() => {
        const getAuthoredReviews = async () => {
            const { authToken, user: { id } = {} } = (await getAuthInfo()) as AuthenticatedUser;
            if (id !== loanOfficer?.id) {
                const authoredReviewsInputs = {
                    lenderId: loanOfficer?.id,
                    totalReviews: loanOfficer?.totalReviews,
                    nmlsId: loanOfficer?.nmlsId,
                    isAuthored: true,
                    isRuntime: true,
                    caller: authToken,
                    userId: id,
                } as LoanOfficerReviewInput;

                const rawResponse = await fetch('/homeloans/loan-officer/api/fullLenderReviews', {
                    method: 'POST',
                    body: JSON.stringify(authoredReviewsInputs),
                });

                try {
                    if (rawResponse.ok) {
                        const { data } = await rawResponse.json();
                        setAuthoredReviews(data);
                    }
                } catch (e) {
                    const errorMessage = getCatchClauseErrorMessage(e);
                    console.error(errorMessage);
                    logger.error({
                        error: errorMessage,
                    });
                }
            } else {
                /** This is used to determine if the "Write a Review" button should be visible
                e.g a loan officer should not be able to write a review
                on their own shareable - lo page */
                setIsWritingReviewVisible(false);
            }
        };

        getAuthoredReviews().catch((e) => {
            console.error(e);
        });
    }, [loanOfficer?.id, loanOfficer?.nmlsId, loanOfficer?.totalReviews]);

    if (error) {
        return (
            <Heading level="2" data-testid="next-error-h1">
                Error: {error}
            </Heading>
        );
    }

    if ([loanOfficer, reviews, error].every((x) => x == null)) {
        <Heading level={2} data-testid="error:no-loan-officer">
            Page does not exist!
        </Heading>;
    }

    const {
        aboutMe,
        companyName,
        emailAddress,
        employerNMLSId,
        nmlsId,
        officePhone,
        hideOfficePhone,
        rating,
        stateLicenses,
        title,
        totalReviews,
        schedulingLink,
    } = loanOfficer ?? {};

    const states: StateAbbreviation[] = Object.keys(stateLicenses ?? {}) as StateAbbreviation[];
    const statesLicenseText = states.reduce(
        (acc, curr, i) =>
            `${acc}${i > 0 ? ', ' : ''}${getStateName(curr)}${
                // eslint-disable-next-line zillow/@typescript-eslint/strict-boolean-expressions
                stateLicenses?.[curr][0] ? ` (${stateLicenses[curr][0]})` : ''
            }`,
        ''
    );

    const preQualifyURL = `/prequalify?nmlsId=${nmlsId}${isPremierAgent ? `&premieragent` : ''}`;
    const lenderName = getLenderDisplayName(loanOfficer as LoanOfficerData, true);
    const firstName = getLenderDisplayName(loanOfficer as LoanOfficerData, false);
    const pageTitle = `Loan Officer page for ${lenderName}`;

    const approxRating = rating ? parseFloat(numberFormatter.format(rating)) : 0.0;

    return (
        <Page backgroundColor="null">
            <Head>
                <title>{pageTitle}</title>
                <meta
                    name="description"
                    content={`Zillow Lender information for ${lenderName}. Features reviews for ${lenderName}, ability to contact, and get pre-qualified.`}
                />
            </Head>
            <FlexContainer style={{ maxWidth: '1280px' }} marginX="auto" marginBottom="lg">
                <ProfileCard data-testid="loan-officer-information:profile-card">
                    <MediaObject
                        align="center"
                        direction="column"
                        media={
                            <Avatar
                                size="xxl"
                                background="blue500"
                                fullName={lenderName}
                                data-testid="loan-officer-information:avatar"
                            >
                                {typeof loanOfficerImage === 'string' ? (
                                    <Image
                                        width={160}
                                        height={160}
                                        alt={lenderName}
                                        src={loanOfficerImage}
                                        priority
                                    />
                                ) : null}
                            </Avatar>
                        }
                    >
                        <Flex
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                        >
                            <Heading
                                level={4}
                                data-testid="loan-officer-information:full-name"
                                marginTop="sm"
                                marginBottom="xs"
                            >
                                {lenderName}
                            </Heading>
                            <Flex
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                marginTop="xs"
                                marginBottom="xs"
                                style={{ width: '100%' }}
                            >
                                <Text
                                    as="div"
                                    style={{ color: '#596B82' }}
                                    data-testid="loan-officer-information:company-name"
                                    fontType="bodySmall"
                                >
                                    {companyName}
                                </Text>
                                {employerNMLSId ? (
                                    <React.Fragment>
                                        <Divider
                                            orientation="vertical"
                                            marginX="xs"
                                            style={{ height: '16px' }}
                                        />
                                        <TextButton
                                            as="a"
                                            href={`https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/${employerNMLSId}`}
                                            style={{
                                                color: '#006AFF',
                                                fontWeight: '400',
                                                display: 'inline-block',
                                            }}
                                            fontType="bodySmall"
                                            target="_blank"
                                            data-testid="loan-officer-information:employer-nmlsid"
                                        >
                                            NMLSID #{employerNMLSId}
                                        </TextButton>
                                    </React.Fragment>
                                ) : null}
                            </Flex>
                            <Text
                                as="div"
                                fontType="body"
                                style={{ letterSpacing: '0.12px' }}
                                data-testid="loan-officer-information:title"
                                marginBottom="xs"
                            >
                                {title}
                            </Text>
                            {nmlsId ? (
                                <TextButton
                                    as="a"
                                    href={`https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/INDIVIDUAL/${nmlsId}`}
                                    style={{
                                        color: '#006AFF',
                                        fontWeight: '400',
                                        display: 'inline-block',
                                    }}
                                    fontType="bodySmall"
                                    target="_blank"
                                    data-testid="loan-officer-information:nmlsid"
                                >
                                    NMLSID #{nmlsId}
                                </TextButton>
                            ) : null}
                            <Flex
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                marginTop="xs"
                                style={{ width: '100%' }}
                            >
                                <RatingStars
                                    as="div"
                                    value={approxRating as unknown as number}
                                    aria-label={`${approxRating} out of 5 stars`}
                                    style={{ margin: '0px 4px' }}
                                    iconFn={roundUpOrDownToHalfStars}
                                />
                                <Text
                                    as="div"
                                    fontType="bodySmall"
                                    style={{
                                        display: 'block',
                                        margin: '0px 4px',
                                    }}
                                    data-testid="loan-officer-information:rating"
                                >
                                    {approxRating} stars •{' '}
                                    <Anchor
                                        href="#reviews"
                                        style={{ color: '#2A2A33', fontSize: '14px' }}
                                        data-testid="loan-officer-information:total-reviews"
                                    >
                                        {totalReviews} {totalReviews === 1 ? 'Review' : 'Reviews'}
                                    </Anchor>
                                </Text>
                            </Flex>
                            <Link
                                href={preQualifyURL}
                                target="_self"
                                style={{ width: '100%' }}
                                data-testid="loan-officer-information:prequalify-link"
                            >
                                <Button
                                    fluid
                                    as="div"
                                    buttonType="primary"
                                    style={{ margin: '20px 0px 0px' }}
                                    data-testid="loan-officer-information:prequalify-button"
                                    onClick={() =>
                                        ddRum.addAction('prequalify button clicked', {
                                            preQualifyURL,
                                            isPremierAgent,
                                        })
                                    }
                                >
                                    Get pre-qualified
                                </Button>
                            </Link>
                            <ButtonGroup aria-label="contact buttons" style={{ width: '100%' }}>
                                {schedulingLink ? (
                                    <Button
                                        fluid
                                        as="a"
                                        buttonType="secondary"
                                        href={schedulingLink}
                                        target="_blank"
                                        style={{ margin: '20px 10px 0px 0px', padding: '8px 4px' }}
                                        data-testid="loan-officer-information:schedule-button"
                                        onClick={() => ddRum.addAction('schedule button clicked')}
                                    >
                                        Schedule a call
                                    </Button>
                                ) : null}
                                {schedulingLink ? (
                                    <ContactLenderModal
                                        lender={{
                                            name: lenderName,
                                            firstName,
                                            imageUrl:
                                                typeof loanOfficerImage === 'string'
                                                    ? loanOfficerImage
                                                    : '',
                                            lenderId: loanOfficer?.id ?? '',
                                        }}
                                    />
                                ) : null}
                            </ButtonGroup>
                            <Text
                                marginTop="xs"
                                as="p"
                                fontType="finePrint"
                                style={{
                                    color: '#596B82',
                                    textAlign: 'center',
                                }}
                                data-testid="loan-officer-information:fine-print"
                            >
                                By clicking <strong>Get pre-qualified</strong>, you&apos;ll complete
                                a few questions and {firstName} will reach out to discuss your goals
                                and what you can afford. Your information is safe and secure with
                                us.
                            </Text>
                            <TextButton
                                as="a"
                                href="https://www.zillowhomeloans.com/privacy/"
                                style={{
                                    color: '#006AFF',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                }}
                                data-testid="loan-officer-information:privacy-policy"
                            >
                                Learn more about our privacy policy
                            </TextButton>
                        </Flex>
                    </MediaObject>
                </ProfileCard>
                <Flex
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ maxWidth: '100%' }}
                    marginTop={{ default: 'lg', lg: 0 }}
                    paddingX={{ default: 'md', lg: 0 }}
                >
                    <Heading
                        level={4}
                        data-testid="loan-officer-information:about-heading"
                        marginTop={0}
                        marginBottom="md"
                    >
                        About {lenderName}
                    </Heading>
                    {hasVideo && videoUrl != null && <ProfileVideo videoUrl={videoUrl} />}
                    <Paragraph
                        marginTop={0}
                        marginBottom="md"
                        style={{ width: '100%' }}
                        data-testid="loan-officer-information:about-me"
                    >
                        <ShowHideWordCount
                            text={aboutMe ?? ''}
                            isHidden={aboutMeHidden}
                            onClick={() => setaboutMeHidden(!aboutMeHidden)}
                            renderButton={({ isHidden, ...props }: { isHidden: boolean }) =>
                                aboutMe != null && aboutMe.split(' ').length > 100 ? (
                                    <Anchor
                                        as="button"
                                        aria-expanded={!isHidden}
                                        {...props}
                                        style={{ fontWeight: '700', color: '#006AFF' }}
                                    >
                                        {isHidden ? 'Read More' : 'Read Less'}{' '}
                                    </Anchor>
                                ) : null
                            }
                        />
                    </Paragraph>
                    <Text as="h5" fontType="h6">
                        Licensed to work in:
                    </Text>
                    <Paragraph
                        marginTop="sm"
                        marginBottom="md"
                        data-testid="loan-officer-information:states-licensed"
                    >
                        <ShowHideWordCount
                            text={statesLicenseText}
                            isHidden={stateLicensesHidden}
                            onClick={() => setStateLicensesHidden(!stateLicensesHidden)}
                            renderButton={({ isHidden, ...props }: { isHidden: boolean }) =>
                                statesLicenseText.split(' ').length > 100 ? (
                                    <Anchor
                                        as="button"
                                        aria-expanded={!isHidden}
                                        {...props}
                                        style={{ fontWeight: '700', color: '#006AFF' }}
                                    >
                                        {isHidden ? 'Read More' : 'Read Less'}{' '}
                                    </Anchor>
                                ) : null
                            }
                        />
                    </Paragraph>
                    <ContactCard>
                        <Text
                            as="h5"
                            fontType="h6"
                            style={{
                                marginTop: '20px',
                                marginBottom: '16px',
                                marginLeft: '24px',
                                marginRight: '24px',
                            }}
                        >
                            Contact info
                        </Text>
                        <Flex
                            display="flex"
                            flexDirection={{ default: 'column', md: 'row' }}
                            flexWrap={{ default: 'nowrap', md: 'wrap' }}
                            marginTop="xs"
                            style={{
                                rowGap: '8px',
                                marginLeft: '24px',
                                marginRight: '24px',
                                marginBottom: '26px',
                            }}
                        >
                            {officePhone && !hideOfficePhone ? (
                                <TextButton
                                    as="a"
                                    href={`tel:${officePhone.areaCode}${officePhone.prefix}${officePhone.number}}`}
                                    style={{
                                        color: '#006AFF',
                                        fontWeight: '400',
                                        display: 'inline-block',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        flexBasis: '50%',
                                    }}
                                    target="_blank"
                                    data-testid="loan-officer-information:office-phone"
                                >
                                    <IconPhoneOutline
                                        marginRight="xs"
                                        style={{
                                            color: '#596B82',
                                            width: '20px',
                                            height: 'auto',
                                            marginTop: '2px !important',
                                        }}
                                    />
                                    ({officePhone.areaCode}) {officePhone.prefix}-
                                    {officePhone.number}
                                </TextButton>
                            ) : null}

                            {emailAddress ? (
                                <TextButton
                                    as="a"
                                    href={`mailto:${emailAddress}`}
                                    style={{
                                        color: '#006AFF',
                                        fontWeight: '400',
                                        display: 'inline-block',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        flexBasis: '50%',
                                    }}
                                    target="_blank"
                                    data-testid="loan-officer-information:email-address"
                                >
                                    <IconMailOutline
                                        marginRight="xs"
                                        style={{
                                            color: '#596B82',
                                            width: '20px',
                                            height: 'auto',
                                            marginTop: '2px !important',
                                        }}
                                    />
                                    {emailAddress}
                                </TextButton>
                            ) : null}
                        </Flex>
                    </ContactCard>
                    <LoanOfficerFAQ />
                    {hasValidReviews(authoredReviews) ? (
                        <AuthoredReviewContainer
                            reviews={authoredReviews}
                            screenName={loanOfficerName}
                        />
                    ) : null}

                    <Divider marginTop="lg" length="fluid" />
                    {hasValidReviews(reviews) ? (
                        <ReviewContainer
                            firstName={firstName}
                            lender={{
                                name: lenderName,
                                screenName: loanOfficerName,
                                imageUrl:
                                    typeof loanOfficerImage === 'string' ? loanOfficerImage : '',
                            }}
                            totalReviews={totalReviews as number}
                            rating={approxRating}
                            reviews={reviews as LoanOfficerReview[]}
                            iconFn={roundUpOrDownToHalfStars}
                            isWritingReviewVisible={isWritingReviewVisible}
                        />
                    ) : null}
                    <Divider marginTop="xl" length="fluid" />
                </Flex>
            </FlexContainer>
        </Page>
    );
};

const setResponseAuthorsInformation = async (loanOfficerReviews: LoanOfficerReview[]) => {
    await Promise.all(
        loanOfficerReviews.map(async (review: LoanOfficerReview) => {
            if (!review.response?.authorId) {
                return;
            }

            const { authorId } = review.response;
            const possibleReviewees: string[] = [
                review.individualReviewee?.id,
                review.companyReviewee?.id,
            ].filter(Boolean) as string[];

            if (!possibleReviewees.includes(authorId)) {
                // eslint-disable-next-line no-param-reassign
                delete review.response;
                return;
            }

            const responseData = (await getLenderInformation({
                lenderId: authorId,
                fields: ['imageId', 'individualName', 'screenName', 'companyName'],
            })) as
                | Pick<LoanOfficerData, 'imageId' | 'individualName' | 'screenName' | 'companyName'>
                | ErrorObject;

            if ('error' in responseData) {
                logger.error({
                    context: {
                        authorId,
                        review,
                        message: 'Error fetching response author information',
                    },
                    msg: responseData.error,
                });
                return;
            }
            const imageUrlResponse = await getLoanOfficerImage(authorId, responseData.imageId);
            const imageUrl = typeof imageUrlResponse === 'string' ? imageUrlResponse : undefined;
            const resInfo: ResponseInformation = {
                // eslint-disable-next-line zillow/@typescript-eslint/no-unnecessary-condition
                ...(responseData.individualName && { individualName: responseData.individualName }),
                ...(responseData.screenName && { screenName: responseData.screenName }),
                ...(responseData.companyName && { companyName: responseData.companyName }),
                imageUrl,
            };
            // eslint-disable-next-line no-param-reassign
            review.response = {
                ...review.response,
                ...resInfo,
            };
        })
    );
};

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {
    const res = await getLoanOfficerList();

    if (isLoanOfficerList(res)) {
        const [loanOfficerIdentifier, premierAgent] = context.params?.loanOfficerPath as string[];
        const isPremierAgent = premierAgent === PREMIER_AGENT_PATH;
        if (!Number.isNaN(Number(loanOfficerIdentifier))) {
            const loanOfficerNmlsIdFromUrl = Number(loanOfficerIdentifier);
            logger.info(
                `LO Profile page has been requested with NMLS ID ${loanOfficerNmlsIdFromUrl} and premier agent path ${isPremierAgent}`
            );
            const loanOfficerName = getLoanOfficerNameFromNmlsid(res, loanOfficerNmlsIdFromUrl);
            if (loanOfficerName === undefined) {
                logger.error({
                    msg: `Loan officer with nmls id ${loanOfficerNmlsIdFromUrl} not found`,
                });
                return {
                    props: {
                        error: `Loan officer with nmls id ${loanOfficerNmlsIdFromUrl} not found`,
                    },
                };
            }
            logger.info(
                `Redirecting to LO Profile page with name ${loanOfficerName} and premier agent path ${isPremierAgent}`
            );
            return {
                redirect: {
                    destination: `/${loanOfficerName}${
                        isPremierAgent ? `/${PREMIER_AGENT_PATH}` : ''
                    }`,
                    permanent: true,
                },
            };
        }
        const loanOfficerName = loanOfficerIdentifier;
        const loanOfficerDetails = res[loanOfficerName];
        if (loanOfficerDetails == null) {
            logger.error({
                msg: 'Loan officer not found',
            });
            return { notFound: true, revalidate: REVALIDATE_TIME };
        }
        try {
            const loanOfficerData = (await getLenderInformation({
                loanOfficerNMLSId: loanOfficerDetails.nmlsID,
                schedulingLink: loanOfficerDetails.zohoBookingURL,
            })) as LoanOfficerData | ErrorObject;
            if ('error' in loanOfficerData) {
                logger.error({
                    msg: loanOfficerData.error,
                });
                if (IS_ISR_REQUEST) {
                    throw new Error(loanOfficerData.error);
                }
                return { notFound: true, revalidate: REVALIDATE_TIME };
            }
            const loanOfficerImage = await getLoanOfficerImage(
                loanOfficerData.id,
                loanOfficerData.imageId
            );

            const publishedReviewsInputs = {
                lenderId: loanOfficerData.id,
                totalReviews: loanOfficerData.totalReviews,
                isAuthored: false,
                isRuntime: false,
            } as LoanOfficerReviewInput;

            const loanOfficerReviews = await getLoanOfficerReviewInformation(
                publishedReviewsInputs
            );

            if (!('error' in loanOfficerReviews)) {
                await setResponseAuthorsInformation(loanOfficerReviews);
            }
            // IF ISR request, throw error if there is an error this will prevent
            // the page from being cached with errored data
            if (IS_ISR_REQUEST) {
                if ('error' in loanOfficerReviews) {
                    throw new Error(loanOfficerReviews.error);
                }
                if (typeof loanOfficerImage !== 'string' && 'error' in loanOfficerImage) {
                    throw new Error(loanOfficerImage.error);
                }
            }

            return {
                props: {
                    loanOfficer: loanOfficerData,
                    loanOfficerName,
                    loanOfficerImage,
                    reviews: loanOfficerReviews,
                    isPremierAgent,
                    videoUrl: loanOfficerDetails.videoUrl || null,
                },
                // Incremental Static Regeneration
                // https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration
                // 45 minutes
                revalidate: REVALIDATE_TIME,
            };
        } catch (e) {
            logger.error({ msg: `error occurred fetching information: ${e}` });
            return { notFound: true, revalidate: REVALIDATE_TIME };
        }
    }
    logger.error({
        msg: res.error,
    });
    if (IS_ISR_REQUEST) {
        throw new Error(res.error);
    }
    return { notFound: true, revalidate: REVALIDATE_TIME };
};

export const getStaticPaths: GetStaticPaths = async () => {
    // When this is true (in preview environments) don't
    // prerender any static pages
    // (faster builds, but slower initial page load)
    if (process.env.SKIP_BUILD_STATIC_GENERATION) {
        return {
            paths: [],
            fallback: 'blocking',
        };
    }

    const data = await getLoanOfficerList();

    if (typeof data.error === 'string') {
        return {
            paths: [],
            fallback: 'blocking',
        };
    }

    const paths = Object.keys(data).reduce<LoanOfficerURLPath[]>((acc, curr) => {
        acc.push({ params: { loanOfficerPath: [curr] } });
        acc.push({ params: { loanOfficerPath: [curr, PREMIER_AGENT_PATH] } });
        return acc;
    }, []);
    // Note: In this scenario we know we do not have the nmlsID for the loan officer
    // so we cannot get the information for the loan officer. We will return a 404
    return {
        paths,
        fallback: 'blocking',
    };
};

export default LoanOfficerPage;
