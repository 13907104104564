import React from 'react';
import { Anchor, Heading, List, Paragraph, token } from '@zillow/constellation';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';

const Subtopic = styled(Heading)`
    font-size: token('fontSizes.body');
    color: token('colors.gray600');
    font-weight: token('fontWeights.body');
`;
const FaqParagraph = styled(Paragraph)`
    margin-bottom: ${token('spacing.md')}px;
`;
const FaqList = styled(List)`
    margin-bottom: ${token('spacing.md')}px;
`;

const CreditScoreFAQ: FunctionComponent = () => (
    <React.Fragment>
        <FaqParagraph>
            There&apos;s no impact on your credit score to become pre-qualified with Zillow Home
            Loans.
        </FaqParagraph>
        <FaqParagraph>
            Lenders will base pre-qualification on the information you provide. Some lenders,
            including Zillow Home Loans, may do a “soft pull” of your credit, but that won&apos;t
            affect your credit score.
        </FaqParagraph>
        <FaqParagraph>
            <Anchor href="https://www.zillow.com/homeloans/">
                Learn more about Zillow Home Loans
            </Anchor>
        </FaqParagraph>
    </React.Fragment>
);

const PreQualifyMeaningFAQ: FunctionComponent = () => (
    //

    <React.Fragment>
        <FaqParagraph>
            A mortgage pre-qualification is the process where a lender reviews your self-reported
            income, debts and assets to determine an approximate loan amount you may be able to
            borrow.
        </FaqParagraph>
        <FaqParagraph>Benefits of getting pre-qualified:</FaqParagraph>
        <Subtopic level="6" fontFamily="sansSerif">
            See what you can afford
        </Subtopic>
        <FaqParagraph>
            Shop with confidence knowing which homes will fit best within your budget.
        </FaqParagraph>
        <Subtopic level="6" fontFamily="sansSerif">
            Reach your financing goals
        </Subtopic>
        <FaqParagraph>
            Get connected with certified loan officers at Zillow Home Loans or from Zillow&apos;s
            network of lenders to answer your questions.
        </FaqParagraph>
        <FaqParagraph>
            <Anchor href="https://www.zillow.com/homeloans/">
                Learn more about Zillow Home Loans
            </Anchor>
        </FaqParagraph>
    </React.Fragment>
);
const WhatHappensAfterwardsFAQ: FunctionComponent = () => (
    <React.Fragment>
        <FaqList appearance="number">
            <List.Item>
                First, you&apos;ll answer [10-15 questions] about the home you&apos;re looking to
                buy, your credit score, and finances. It takes as little as 3 minutes{' '}
                <strong>with no impact to your credit score</strong>.
            </List.Item>
            <List.Item>
                Once pre-qualified, you&apos;ll receive a downloadable letter* to share with your
                agent.
            </List.Item>
            <List.Item>
                Next, you&apos;ll connect with a loan officer to confirm your information.
            </List.Item>
        </FaqList>
        <FaqParagraph>*Exclusions apply based on state and employment regulations.</FaqParagraph>
        <FaqParagraph>
            The next step is to get pre-approved. Most buyers do this before submitting an offer so
            they can show the seller they&apos;re serious and have the financial backing to purchase
            the home.
        </FaqParagraph>
    </React.Fragment>
);

export { CreditScoreFAQ, PreQualifyMeaningFAQ, WhatHappensAfterwardsFAQ };
