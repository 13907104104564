import { Avatar, IconUser, Image } from '@zillow/constellation';
import type { FunctionComponent } from 'react';
import React from 'react';

interface LenderImageAvatarProps {
    lender: {
        name: string;
        imageUrl?: string;
    };
}

export const LenderImageAvatar: FunctionComponent<LenderImageAvatarProps> = ({
    lender: { name, imageUrl },
}) => (
    <Avatar
        background="blue500"
        fullName={name}
        data-testid="review-card:response-avatar"
        marginRight={0}
        size={{ default: 'md' }}
    >
        {typeof imageUrl === 'string' && !!imageUrl ? (
            <Image width={48} height={48} alt={name} src={imageUrl} />
        ) : (
            <IconUser />
        )}
    </Avatar>
);
